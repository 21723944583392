export default {
  foundationName: "Rafmyntaráð Íslands",
  newsletterPlaceholder: "Sláðu inn netfangið þitt fyrir fréttabréfið",
  foundationDescriptionText: `Markmiðið okkar er að gera Ísland að leiðandi afli í nýsköpun á rafmyntum og bálkakeðjum`,
  foundationDescription: `<p>Markmiðið okkar er að gera Ísland <br /> að leiðandi afli í nýsköpun á <br />
      <strong>rafmyntum</strong>
      og <strong>bálkakeðjum</strong></p>`,
  aboutHeading: "Um samtökin",
  events: "Viðburðir",
  goToEvents: "fleiri viðburðir",
  recentPosts: "Nýlegar greinar",
  visitBlog: "fleiri greinar",
  workingGroups: "Vinnuhópar",
  visitWorkingGroups: "fleiri vinnuhópar",
  newsletter: "Fréttabréf",
  newsletterThanks: "Takk fyrir að skrá þig í fréttabréfið",
  join: "Skrá",
  newsletterDescription:
    "Mánaðarlegar uppfærslur og fréttir, aldrei fjölpóstur",
  newsletterCTA: `<p>Skráðu þig í <strong>fréttabréfið</strong> <br />fyrir mánaðarlegum uppfærslum og fréttum</p>`,
  enterEmail: "Sláðu inn netfangið þitt",
  eventsBackgroundHeading: "viðburðir",
  postsBackgroundHeading: "greinar",
  foundationBackgroundHeading: "samtökin",
  groupsBackgroundHeading: "hópar",
  resources: "Ítarefni",
  learn: "Lærðu",
  contact: "Hafa Samband",
  followUs: "Fylgdu okkur",
  followUsOn: "Fylgdu okkur á %s",
  logo: "firmamerki",
  about: "Um Samtökin",
  posts: "Greinar",
  members: "Meðlimir",
  nextEvent: "Næsti hittingur hjá hóp",
  notFoundHeading: "SÍÐA FANNST EKKI",
  notFoundBody: "Síðan sem þú ert að leita að fannst ekki...",
  workingGroupsMenu: "Hópar",
  leadBy: "%s stýrir hópnum",
  bylaws: "Samþykktir",
  executiveSummary: "Samantekt um samtökin",
  whatIsCrypto: "Hvað eru rafmyntir?",
  whatIsBlockchain: "Hvað eru bálkakeðjur?",
  otherWorkingGroups: "Aðrir vinnuhópar",
  blog: "grein",
  listenToEpisodeHeading: "Hlustaðu á þáttinn:",
  register: "Nýskráning í samtökin",
  pageNotFound: "Síða fannst ekki",
  address: "Bjargargata 1, 2. hæð",
  education: "Fræðsla",
  educationContent:
    "Rafmyntaráð hefur tekið saman viðamikið safn af fræðsluefni í formi myndbanda, hlaðvarpa og greina.",
  educationMaterial: "Fræðsluefni",
  podcast: "Hlaðvarp",
  podcastContent:
    "Hlaðvarp Rafmyntaráðs fjallar um fjármálakerfið og tækni, ásamt hugvekju um ýmis mál.",
  phone: "",
  donateFooter:
    "Styrktu okkur \n| Bitcoin: 1HN7dLXP9y3Jyn2emS6Jt9M86ecFPaj9hs | Krónur rn: 0133-26-440515, kt: 440515-1340",
  noUpcomingEvents: "Engir viðburðir á döfinni",
  // registrationCount: "Í þessum mánuði hafa <strong>%s manns</strong> skráð sig",
  registrationCount:
    "Skráðu þig sem <strong>félaga</strong> í Rafmyntaráð Íslands",
  registrationFee:
    "Árgjaldið er einungis 5.000 kr. og veittur er 25% afsláttur ef greitt er með rafmyntum!",
  becomeMember: "Gerast félagi",
  benefitsLegend: "Ávinningur",
  benefitsBackground: "ávinningur",
  donations: "Styrkir",
  donate: "styrkja",
  name: "nafn",
  email: "netfang",
  ssn: "kennitala",
  // registrationPolicy: `Með því að smella á Skrá mig, gefur þú til kynna að þú hafir lesið Persónuverndarstefnuna og Notkunarskilmálana`,
  registrationPolicy: `Með því að smella á Skrá mig, heimilar þú vörslu þessara takmörkuðu gagna í félagaskrá samtakanna.`,
  registerNow: "SKRÁ MIG",
  benefitsOfMembership: "Ávinningurinn af skráningu",
  influenceOurWork: "Hafðu áhrif á starfsemina",
  influenceOurWorkDescription:
    "Við erum félagasamtök sem vinna eftir markmiðum sem eru skilgreind af félagsmönnum.",
  supportMeaningfulWork: "Stuðningur við starf sem skiptir máli",
  supportMeaningfulWorkDescription:
    "Við erum eini skilgreindi hópurinn sem berst fyrir rafmyntum á Íslandi. Rafmyntaráð býr til mikið af les- og kynningarefni á íslensku og heldur fjölda viðburða.",
  campaignTheBoard: "Framboð til stjórnar",
  campaignTheBoardDescription:
    "Félagar geta boðið sig fram í stjórn Rafmyntaráðs til eins árs í senn. Stjórnin setur markmið fyrir starfið og passar að þeim sé framfylgt.",
  expandSupportNetwork: "Stækkaðu tengslanetið",
  expandSupportNetworkDescription:
    "Félagar geta stofnað faghópa um málefni bálkakeðja og rafmynta. Faghóparnir gegna mikilvægu hlutverki til draga eins hugsandi fólk saman.",
  attendPrivateEvents: "Viðburðir aðeins fyrir félaga",
  attendPrivateEventsDescription:
    "Rafmyntaráð skipuleggur að lágmarki einn viðburð á ári sem er eingöngu fyrir félaga samtakanna.",
  supportYourProject: "Við styðjum þitt verkefni",
  supportYourProjectDescription:
    "Ef að þú ert að vinna við verkefni í geiranum munum við styðja þig alla leið eins og kostur er.",
  weAppreciateDonations: "Við erum þakklát fyrir <strong>styrki</strong>",
  weAppreciateDonationsDescription: `Þörfin fyrir samtök eins og <span>Rafmyntaráð Íslands</span> er mikil. Samtökin móta framtíðina með áherslu á að fólk og fyrirtæki sjái augljósa kosti rafmynta og bálkakeðja. Rafmyntaráð leiðir þessa vinnu og hjálpar fyrirtækjum í geiranum að ná árangri. Stuðningur við samtökinn drífur okkur áfram og hjálpar okkur að framfylgja markmiðunum okkar.`,
  transferBTC: "Stykja með Bitcoin (BTC)",
  transferAUR: "Styrkja með Auroracoin (AUR)",
  bankWire: "Styrkja með Bankamillifærslu",
  bankAccount: "Reikningsnúmer",
  ssnAccount: "Kennitala",
  registrationThanks: "Takk fyrir að skrá þig í Rafmyntaráð Íslands",
  companiesAndProjects: "Fyrirtæki og verkefni",
};
